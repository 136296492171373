import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {PropertyService} from "../../../../views/app/properties/property.service";
import {NgxSpinnerService} from "ngx-spinner";
import {GlobalService} from "../../../global.service";
import {Router} from "@angular/router";
import {PropertyGlobalService} from "../../../property-global.service";
import {Subscription} from "rxjs";
import Swal from "sweetalert2";
import {NotificationsService, NotificationType} from "angular2-notifications";
import {TranslateService} from "@ngx-translate/core";
import {ModalDirective} from "ngx-bootstrap/modal";
import {NgForm} from "@angular/forms";
import {SidebarService} from "../../../../containers/layout/sidebar/sidebar.service";

@Component({
  selector: 'app-property-list-view',
  templateUrl: './property-list-view.component.html',
  styleUrls: ['./property-list-view.component.scss']
})
export class PropertyListViewComponent implements OnInit{
  optionsUpdates = {};
  soldProperties: any[] = [];
  propertyList: any[] = [];
  propertyCount = 0;
  restoreList: any = [];
  sharedProperties: any = [];
  isShare = false;
  items: any = [];
  selectedPermissionList: any = [];
  shareButtonDisabled = false;
  responseWaiting = '';
  propertyDetails: any = [];
  routeParamsSubscription: Subscription;
  images: any = [];
  propertyNameOnTitles: any = [];
  volumeOrFolios: any = [];
  errorList: any = [];
  sharedPropertiesTemp: any = [];
  email = '';
  permissionList: any = [];
  propertyRef = null
  userName = '';
  userEmail = '';
  isOpenShareModal = false;
  showSelectAllView = false;
  showSelectAllEdit = false;
  showSelectAllDelete = false;
  hiddenModal = false;
  imageItems: any[] = [
    {
      "id": "large-0",
      "img": "https://strgpmsdevelopment.blob.core.windows.net/pmsimages/53/2018654772_13_3_230719_065046-w2480-h3508.jpeg"
    },
  ];

  imageItemsThumbs: any[]  = [
    {
      "id": "thumb-0",
      "img": "https://strgpmsdevelopment.blob.core.windows.net/pmsimages/53/2018654772_13_3_230719_065046-w2480-h3508.jpeg"
    },
  ];
  showDetails = true;

  @Input() totalProperties: number = 0;
  @Input() totalPropertyPurchase: number = 0;
  @Input() totalPropertyMarketValue: number = 0;
  @Input() totalPropertyGain: number = 0;
  subsVar: Subscription;
  // totalPropertyGain: number = 0;
  @Input() rows: any[] = [];
  @Input() hiddenProperties: any[] = [];
  @Input() isSold: boolean = localStorage.getItem('isSold') == "1" ? true : false;
  @Input() showSold: boolean = false;
  @Output() changeSortOrder = new EventEmitter();
  @ViewChild('unshare_model') unshareModel: ModalDirective;
  @ViewChild('editShareForm') editShareForm: NgForm;
  constructor(
    private propertyService: PropertyService,
    private spinner: NgxSpinnerService,
    private globalService: GlobalService,
    private router: Router,
    private propertyGlobalService: PropertyGlobalService,
    private globalServie: GlobalService,
    private notifications: NotificationsService,
    private translate: TranslateService,
    private sidbarService: SidebarService
  ) {
    this.subsVar = this.propertyGlobalService.propertyListInListViewEvent.subscribe((data) => {
      console.log('data', data);
      this.rows = data['propertyList'];
      this.isSold = localStorage.getItem('isSold') == "1" ? true : false;
      // this.calculateTotals();
    });

    this.propertyGlobalService.openSharedModelEvent.subscribe(data => {
      if(data.shared_users != '') {
        this.sharedProperties = data.shared_users;
      }
      this.hiddenModal = false;
    });
  }

  ngOnInit(): void {
    // this.calculateTotals();
  }

  async drop(event: CdkDragDrop<string[]>, array: any[]) {
    // this.spinner.show();
    let sortArray:any [] =[]
    await moveItemInArray(array, event.previousIndex, event.currentIndex);

    await array.map((el, index) => {
      let data={
        "propertyRef":el.property_ref,
        "sort_order" : index
      };
      sortArray.push(data);
    });


    await this.propertyService.sortOrderProperty(sortArray).subscribe(response => {
      // this.spinner.hide();
      this.changeSortOrder.emit(true);
      // this.onSuccess('Sort Successful!');
    }, err => {
      this.globalService.onError(err.message);
      // this.spinner.hide();
    });
  }

  navigateRoute(row: any) {
    this.spinner.show();
    this.propertyService.getSnapshotValidation(row.property_ref).subscribe(response => {
      this.globalService.notificationChange(response['result']);
      localStorage.setItem('snapShotValidation', response['result']);
      let data ={
        permission_list:response['user_permissions'],
        snapShotVisibility:response['result']
      }
      this.globalService.permissionChange(data);
      this.sidbarService.propertyListMenuShowHide(true);
      var url = '';
      if (response['result'] == true) {
        // url = '/app/property/snapshot/' + row.property_ref;
        url = '/app/properties/details/' + row.property_ref;
      } else {
        url = '/app/properties/details/' + row.property_ref;
      }
      this.router.navigateByUrl(url);
      this.spinner.hide();
    }, err => {
      this.globalService.onError(err.status);
      this.spinner.hide();
    });
  }

  // calculateTotals() {
  //   this.totalSoldProperties = 0;
  //   this.totalProperties = 0;
  //   this.totalPropertyPurchase = 0;
  //   this.totalPropertyMarketValue = 0;
  //   this.totalPropertyGain = 0;
  //   this.rows.forEach((item) => {
  //     if(this.isSold == true){
  //       this.totalSoldProperties++;
  //       this.totalSoldPropertyPurchase = this.totalSoldPropertyPurchase + item['purchase_price'];
  //       this.totalSoldPropertyMarketValue = this.totalSoldPropertyMarketValue + item['sold_price'];
  //       this.soldProperties.push(item);
  //     }
  //     else {
  //       this.totalProperties++;
  //       this.totalPropertyPurchase = this.totalPropertyPurchase + item['purchase_price'];
  //       this.totalPropertyMarketValue = this.totalPropertyMarketValue + item['estimated_value_now'];
  //       this.propertyList.push(item);
  //     }
  //   });
  //
  //   this.totalPropertyGain = (((this.totalPropertyMarketValue - this.totalPropertyPurchase) / this.totalPropertyPurchase) * 100);
  //   this.totalSoldPropertyGain = (((this.totalSoldPropertyMarketValue - this.totalSoldPropertyPurchase) / this.totalSoldPropertyPurchase) * 100);
  //
  //   // this.totalPropertyGain = this.totalPropertyMarketValue - this.totalPropertyPurchase;
  // }


  removeProperty(propertyRef) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want delete this property!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((isConfirm) => {
      if (isConfirm.value) {
        this.spinner.show();
        this.propertyService.deleteProperty(propertyRef).subscribe(res => {
          this.spinner.hide();
          if (res['status'] == 200) {
            this.propertyCount = res['propCount'];
            this.globalServie.updatePropertyCount(this.propertyCount,res['not_my_house_count']);
            this.onSuccess('Delete successful');
            this.propertyGlobalService.getPropertyReload(true);
          } else {
            this.onError(res['message']);
          }
        }, err => {
          this.spinner.hide();
          this.onError(err.message);
          this.onError(err.status);
        });
      }
    }, function (desmiss) {
    });
  }

  onError(msg) {
    this.notifications.create(this.translate.instant('alert.error'), msg, NotificationType.Error, {
      timeOut: 3000,
      showProgressBar: true
    });
  }

  onSuccess(msg) {
    this.notifications.create(this.translate.instant('alert.success'), msg, NotificationType.Success, {
      timeOut: 3000,
      showProgressBar: true
    });
  }

  hideProperty(propertyRef) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want hide this property!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, hide it!'
    }).then((isConfirm) => {
      if (isConfirm.value) {
        this.spinner.show();
        this.propertyService.hideProperty(propertyRef).subscribe(res => {
          this.spinner.hide();
          if (res['status'] == 200) {
            this.onSuccess('Your property is hidden ..');

            this.propertyGlobalService.propertyListChange(res);
          } else {
            this.onError(res['message']);
          }
          this.restoreList = [];
        }, err => {
          this.spinner.hide();
          this.onError(err.message);
          this.onError(err.status);
        });
      }
    }, function (desmiss) {
    });
  }

  shareProperty(row) {
    this.isShare = true;
    this.propertyRef = row.property_ref;
    this.getPropertyDetails();
    this.unshareModel.show();
    if (row.shared_users.length > 0) {
      this.sharedProperties = row.shared_users;
    } else {

      this.sharedProperties = [];
    }
  }

  async  getPropertyDetails() {
    this.spinner.show();
    await this.propertyService.getPropertyDetails(this.propertyRef,'propertyEdit').subscribe(response => {
        this.spinner.hide();
        try{
          this.propertyDetails = response['properties'];
          this.images = response['images_lightbox'];
          this.imageItems = response['imageItems'];
          this.imageItemsThumbs = response['imageItemsThumbs'];
          this.propertyNameOnTitles = response['name_on_titles'];
          this.volumeOrFolios = response['volume_or_folios'];
          this.permissionList = response['permission_list'];
          this.validatePropertyPurpose();
        } catch (e) {
          this.globalService.onError(e.message) ;
        }

      },
      err => {
        this.spinner.hide();
      });
  }

  validatePropertyPurpose() {
    if (this.propertyDetails['purpose'] != null) {
      if (this.propertyDetails['purpose'] != 'Land Bank') {
        this.showDetails = true;
      }
      else {
        this.showDetails = false;
      }
    }
    else {
      this.showDetails = true;
    }
  }


  openHiddenPropertyModal() {
    let data = {
      hiddenProperties: this.hiddenProperties,
    }
    this.propertyGlobalService.openHiddenPropertyModal(data);
  }

  closeunShareModel(unshare_model: ModalDirective, unshareForm: NgForm) {
    unshare_model.hide();
  }

  openEditSharePermissions(edit_share_model: ModalDirective, Permissions, data) {
    edit_share_model.show();
    this.selectedPermissionList = Permissions;
    this.userName = data.first_name;
    this.userEmail = data.email;
    this.isOpenShareModal = true;
    this.hiddenModal = true;
  }


  unshareTo(unshare_model: ModalDirective, form: NgForm, data) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'you want to unshare this property!',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, unshare it!'
    }).then((isConfirm) => {
      if (isConfirm.value) {
        this.propertyService.unshareTo(data).subscribe(res => {
          this.spinner.hide();
          if (res['status'] == 200) {
            this.onSuccess('Property unshared successful');
            unshare_model.hide();
            this.propertyGlobalService.getPropertyReload(true);
          } else {
            this.onError(res['message']);
          }
        }, err => {
          this.spinner.hide();
          this.onError(err.message);
          this.onError(err.status);
          this.shareButtonDisabled = false;
        });
      }
    }, function (desmiss) {
    });
  }

  openPropertyShareModal() {
    // this.isOpenShareModal = true;
    this.hiddenModal = true;
    this.showSelectAllView = false;
    this.showSelectAllEdit = true;
    this.showSelectAllDelete = true;
    this.permissionList.forEach(val => {
      val.view = true, val.edit = false , val.delete == false
    });
    this.items = [];
    this.isShare = true;
    let data = {
      'propertyRef': this.propertyRef,
      'isShare': true,
      'permissionList': this.permissionList,
      'comeFrom': 'property-list-tile-view',
    }
    this.propertyGlobalService.openSharePropertyModel(data);
  }


  tickAllCheckBoxViewInEdit() {
    if (this.selectedPermissionList.every(val => val.view == true))
    {
      this.selectedPermissionList.forEach(val => { val.view = false, val.edit = false , val.delete = false});
      this.showSelectAllView = true;
      this.showSelectAllEdit = true;
      this.showSelectAllDelete = true;
    }
    else{
      this.selectedPermissionList.forEach(val => { val.view = true });
      this.showSelectAllView = false;
    }

  }

  tickAllCheckBoxDelete(isSelectAll) {
    let count = 0;
    this.permissionList.forEach(val => {
      if (val.view) {
        count++;
      }
    });
    if (count > 0) {
      if (isSelectAll) {
        this.showSelectAllDelete = false;
        this.permissionList.forEach(val => {
          if (val.view) {
            val.delete = true;
          }
        });
      } else {
        this.showSelectAllDelete = true;
        this.permissionList.forEach(val => {
          val.delete = false
        });
      }
    } else {
      this.permissionList.forEach(val => {
        val.delete = true, val.view = true
      });
      this.showSelectAllView = false;
      this.showSelectAllDelete = false;
    }
  }

  tickAllCheckBoxEditInEdit(isSelectAll) {
    let count = 0;
    this.selectedPermissionList.forEach(val => {
      if(  val.view){
        count++;
      }
    });
    if(count>0) {
      if (isSelectAll) {
        this.showSelectAllEdit = false;
        this.selectedPermissionList.forEach(val => {
          if (val.view) {
            val.edit = true;
          }
        });
      } else {
        this.showSelectAllEdit = true;
        this.selectedPermissionList.forEach(val => {
          val.edit = false
        });
      }
    }
    else{
      this.selectedPermissionList.forEach(val => { val.delete = true, val.view = true});
      this.showSelectAllView = false;
      this.showSelectAllEdit = false;
    }
  }

  tickAllCheckBoxDeleteInEdit(isSelectAll) {
    let count = 0;
    this.selectedPermissionList.forEach(val => {
      if(  val.view){
        count++;
      }
    });
    if(count>0) {
      if (isSelectAll) {
        this.showSelectAllDelete = false;
        this.selectedPermissionList.forEach(val => {
          if (val.view) {
            val.delete = true;
          }
        });
      } else {
        this.showSelectAllDelete = true;
        this.selectedPermissionList.forEach(val => {
          val.delete = false
        });
      }
    }
    else{
      this.selectedPermissionList.forEach(val => { val.delete = true, val.view = true});
      this.showSelectAllView = false;
      this.showSelectAllDelete = false;
    }
  }


  tickAllCheckBoxEdit(isSelectAll) {
    let count = 0;
    this.permissionList.forEach(val => {
      if (val.view) {
        count++;
      }
    });

    if (count > 0) {
      if (isSelectAll) {
        this.showSelectAllEdit = false;
        this.permissionList.forEach(val => {
          if (val.view) {
            val.edit = true;
          }
        });
      } else {
        this.showSelectAllEdit = true;
        this.permissionList.forEach(val => {
          val.edit = false
        });
      }
    } else {
      this.permissionList.forEach(val => {
        val.edit = true, val.view = true
      });
      this.showSelectAllView = false;
      this.showSelectAllEdit = false;
    }
  }

  permissionViewClick(checked, i) {
    if(checked == false){
      this.selectedPermissionList[i]['edit'] = false;
      this.selectedPermissionList[i]['delete'] = false;
    }
  }

  newPermissionClick(checked, i) {
    if(checked == true){
      this.permissionList[i]['view'] = true;
    }
  }

  newPermissionViewClick(checked, i) {
    if(checked == false){
      this.permissionList[i]['edit'] = false;
      this.permissionList[i]['delete'] = false;
    }
  }
  shareToEdit(edit_share_model: ModalDirective, form: NgForm) {
    if (!this.editShareForm.valid || this.shareButtonDisabled) {
      return;
    }
    this.shareButtonDisabled = true;
    let data = {
      'propertyRef': this.propertyRef,
      'permissionList': this.selectedPermissionList,
    };
    this.responseWaiting = 'show-spinner';
    this.propertyService.shareToEdit(data).subscribe(res => {
      this.spinner.hide();
      this.responseWaiting = '';
      this.shareButtonDisabled = true;
      if (res['status'] == 200) {
        this.onSuccess('Property Shared successful');
        this.isShare = false;
        this.shareButtonDisabled = false;
        edit_share_model.hide();
        this.hiddenModal = false
        this.errorList = res['errors_list'];
        this.errorList.forEach((val, index) => {
          this.onError(val['message']);
        });
        this.propertyGlobalService.getPropertyReload(true);
        this.sharedPropertiesTemp = res['shared_users'];
        this.sharedProperties = this.sharedPropertiesTemp;

      } else {
        this.shareButtonDisabled = false;
        this.onError(res['message']);
        this.propertyGlobalService.getPropertyReload(true);
      }
    }, err => {
      this.spinner.hide();
      this.onError(err.message);
      this.onError(err.status);
      this.shareButtonDisabled = false;
    });
  }

  permissionClick(checked, i) {
    if(checked == true){
      this.selectedPermissionList[i]['view'] = true;
    }
  }
  closeShareModel(modal: ModalDirective, form: NgForm) {
    this.isShare = false;
    // form.resetForm();
    modal.hide();
    this.propertyRef = null;
    this.email = '';
    this.hiddenModal = false;
  }

}
