import {Component, OnInit, ViewChild} from '@angular/core';
import {SupplierGlobalService} from "../../../supplier-global.service";
import {ModalDirective} from "ngx-bootstrap/modal";
import {Router} from "@angular/router";

@Component({
  selector: 'app-property-supplier-details-view-model',
  templateUrl: './property-supplier-details-view-model.component.html',
  styleUrls: ['./property-supplier-details-view-model.component.scss']
})
export class PropertySupplierDetailsViewModelComponent implements OnInit {

  @ViewChild('newModalTest') supplierViewModel: ModalDirective;
  supplierDetails = [];
  stars: number[] = [1, 2, 3, 4, 5];
  comeFrom = null;

  constructor(
    private supplierGlobalService:SupplierGlobalService,
    private router: Router
  ) {
    this.supplierGlobalService.openSupplierViewModelEvent.subscribe(data => {
      this.supplierDetails = data['supplier_details'];
      this.comeFrom = data['comeFrom'];
      this.supplierViewModel.show();

    });
  }

  ngOnInit(): void {
  }

  navigateProperty(property_ref: any) {
    this.router.navigate(['app/properties/details/' + property_ref]);
    this.supplierViewModel.hide();
  }

  redirectToGoogleSearch(supplierDetail: any) {
    window.open(`https://www.google.com/search?q=${supplierDetail}`, '_blank');
  }

  redirectToSupplierWebPage(supplierDetail: any) {
    window.open(supplierDetail, '_blank');
  }

  openEmail(supplierDetail: any) {
    let mailText = "mailto:"+supplierDetail; // add the links to body
    window.location.href = mailText;
  }
}
