<simple-notifications></simple-notifications>
<ngx-spinner></ngx-spinner>
<div bsModal #expenseModal="bs-modal" class="modal fade {{isOpenSubModal ? 'hidden' : ''}}" tabindex="-1"
     [config]="{ backdrop: 'static' ,ignoreBackdropClick: true,keyboard: false}" role="dialog"
     aria-labelledby="dialog-sizes-name1">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" *ngIf="come_from != 'eofy'">Add Expenses</h5>
        <h5 class="modal-title" *ngIf="come_from == 'eofy'">Property Expenses {{financial_year}}
          - {{property_address}}</h5>
        <button type="button" class="close" aria-label="Close" (click)="closeModel()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form class="form" [formGroup]="expenseForm"
            novalidate>
        <div class="modal-body pt-2">
          <div class="row justify-content-end mb-2" >
            <div class="col-auto tooltip_learning pr-0">
              <a href="javascript:void(0)" class="btn btn-link text-right" tooltip="Expenses to Check at EOFY"
                 tooltipPlacement="bottom" (click)="getArticleDetails()">
                Expenses to Check at EOFY
              </a>
            </div>
          </div>

          <div class="row mb-3 eofy-exp">
            <div class="col-lg-5 mb-2">
              <label class="form-group has-float-label mb-0">
                <div class="input-group">
                  <ng-select appearance="outline" class="w-100"    [searchable]="false" [clearable]="false"  formControlName="filter_expense_category">
                    <ng-option value="Show All">Show All</ng-option>
                    <ng-option *ngFor="let list of category_type" value="{{list.expense_category}}">{{list.expense_category}}</ng-option>
                  </ng-select>


<!--                  <select class="form-control" formControlName="filter_expense_category">-->
<!--                    <option value="Show All" selected>Show All</option>-->
<!--                    <option *ngFor="let list of category_type" value="{{list.expense_category}}">-->
<!--                      {{list.expense_category}}-->
<!--                    </option>-->
<!--                  </select>-->
                </div>
                <span>Expense Category</span>
                <div *ngIf="isFilter && expenseForm.get('filter_expense_category').errors" class="text-danger">
                  This field is required to filter expenses.
                </div>
              </label>
            </div>
            <div class="col-lg-4 col-md-8 mb-2">
              <button class="btn btn-outline-primary mr-2" type="button" (click)="cancelFilter()">Cancel</button>
              <button class="btn btn-primary" type="button" (click)="filterExpense()">Filter</button>
            </div>
            <div class="col-lg-3 col-md-4 mb-2">
              <button class="btn btn-outline-primary pull-right" type="button" (click)="addNewRow()">Add Expense
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="table-responsive" style="padding-bottom: 240px!important;">
                <table class="table table-borderless">
                  <thead>
                  <tr>
                    <th scope="col" width="15%">Period <span class="text-danger">*</span></th>
                    <th scope="col" width="20%">Expense Category<span class="text-danger">*</span></th>
                    <th scope="col" width="18%">Expense Amount <span class="text-danger">*</span></th>
                    <th scope="col" width="20%">Expense Description</th>
                    <th scope="col" width="20%">Supplier <span class="text-danger">*</span></th>
                    <th scope="col" ></th>
                  </tr>
                  </thead>
                  <tbody>
                  <!--                  <ng-container >-->
                  <ng-container formArrayName="expenseList">
                    <ng-container *ngFor="let item of documentsControl.controls; let index = index"
                                  [formGroupName]='index'>
                      <tr
                        *ngIf="isFilter && expenseForm.get('filter_expense_category').value !='Show All' && documentsControl.controls[index].get('expense_category').value == expenseForm.get('filter_expense_category').value">
                        <td style="min-width: 113px;">
                          <label class="form-group has-float-label  mb-0">
                            <div class="input-group">

                              <input class="form-control" placeholder="dd/mm/YYYY"
                                     name="expense_date" formControlName="expense_date" ngbDatepicker
                                     #date_s="ngbDatepicker" (click)="date_s.toggle()"
                                     (ngModelChange)="dateChange(item,index)">
                            </div>
                            <div *ngIf="submitted && documentsControl.controls[index].get('expense_date').errors"
                                 class="text-danger">This field is required
                            </div>
                            <div *ngIf="documentsControl.controls[index].get('is_error').value == true"
                                 class="text-danger pr-1 pt-2">{{documentsControl.controls[index].get('error_message').value }}</div>
                          </label>
                        </td>
                        <td>
                          <label class="form-group has-float-label mb-0" *ngIf="is_custom_pick_list == false">
                            <div class="input-group">
                              <ng-select class="form-control"
                                         formControlName="expense_category" required
                                         (change)="getLastExpense(item, index);">
                                <ng-option *ngFor="let list of category_type" value="{{list.expense_category}}">
                                  {{list.expense_category}}</ng-option>
                              </ng-select>
                            </div>
                            <div *ngIf="submitted && documentsControl.controls[index].get('expense_category').errors"
                                 class="text-danger">This field is required
                            </div>
                          </label>

                          <label class="form-group has-float-label" *ngIf="is_custom_pick_list == true">
                            <div class="position-relative has-icon-right">
                              <div class="input-group">
                                <ng-select [items]="category_type" class="w-100 explanation-link"
                                           [addTag]="addExpenseCategory" [addTagText]="'Hit Enter to Add Item'"
                                           aria-describedby="inputGroup-sizing-sm" [hideSelected]="true"
                                           [multiple]="false" bindLabel="expense_category" bindValue="expense_category"
                                           formControlName="expense_category" (change)="getLastExpense(item, index);">
                                </ng-select>
                              </div>
                            </div>
                            <span class="text-primary-light">customize Category <a href="javascript:;"
                                                                                   (click)="openExpenseCategoryModel(expenseCategoryModel,'new_expense',index)"><i
                              class="fa fa-pencil text-primary ml-1"></i></a></span>
                            <div *ngIf="submitted && documentsControl.controls[index].get('expense_category').errors"
                                 class="text-danger">This field is required
                            </div>
                          </label>
                        </td>
                        <td>
                          <label class="form-group has-float-label  mb-0">
                            <div class="position-relative has-icon-left">
                              <div class="input-group">
                                <input type="number" class="form-control" aria-describedby="inputGroup-sizing-sm"
                                       formControlName="expense_amount" style="max-width: 136px !important;"  required>
                                <div class="form-control-position">
                                  <span class="input-field-currency-type-size " style="padding-top: 2px">$</span>
                                </div>
                              </div>
                            </div>
                            <div *ngIf="submitted && documentsControl.controls[index].get('expense_amount').errors"
                                 class="text-danger">This field is required
                            </div>
                          </label>
                        </td>
                        <td>
                          <label class="form-group has-float-label mb-0">
                            <div class="input-group">
                              <input type="text" class="form-control" aria-describedby="inputGroup-sizing-sm"
                                     formControlName="description" name="description">
                            </div>
                          </label>
                        </td>
                        <td>
                          <div class="input-group">
                            <ng-select [items]="suppliers" bindLabel="showing_name" bindValue="business_name"
                                       [selectOnTab]="true" class="flex-grow-1 explanation-link"
                                       [addTag]="addNewBank" [multiple]="false" class="w-83-prec h-25"
                                       [addTagText]="'Hit Enter to Add Item'"
                                       [hideSelected]="true" [trackByFn]="trackByFn" [minTermLength]="2"
                                       [loading]="false"
                                       [typeahead]="false" formControlName="business_name"
                                       (keyup)="searchAgent($event);"
                                       (change)="itemChange($event,index)">
                            </ng-select>
                            <div class="input-group-append">
                              <button type="button"
                                      *ngIf="documentsControl.controls[index].get('business_name').value == null"
                                      class="btn btn-primary default pl-2 pr-2" (click)="openSupplierListModel(index)"><i
                                class="fa fa-binoculars"></i></button>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="row">
                            <div class="form-group mb-0 ">
                              <a class="badge badge-success mr-1" href='javascript:void(0)'
                                 (click)="addNewExpenseRow(index)">
                                <i class="fa fa-plus" style="font-size: 15px;"></i>
                              </a>
                              <a class="badge badge-danger mr-1" href='javascript:void(0)'
                                 (click)="removeExpense(documentsControl.controls[index].value,index)">
                                <i class="fa fa-minus" style="font-size: 15px;"></i>
                              </a>
                              <a *ngIf="come_from != 'eofy'" class="badge badge-danger" href='javascript:void(0)'
                                 (click)="copyRow(index)">
                                <i class="fa fa-copy" style="font-size: 15px;"></i>
                              </a>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr *ngIf="isFilter && expenseForm.get('filter_expense_category').value =='Show All'">
                        <td style="min-width: 113px;">
                          <label class="form-group has-float-label  mb-0">
                            <div class="input-group">

                              <input class="form-control" placeholder="dd/mm/YYYY"
                                     name="expense_date" formControlName="expense_date" ngbDatepicker
                                     #date_s="ngbDatepicker" (click)="date_s.toggle()"
                                     (ngModelChange)="dateChange(item,index)">
                            </div>
                            <div *ngIf="submitted && documentsControl.controls[index].get('expense_date').errors"
                                 class="text-danger">This field is required
                            </div>
                            <div *ngIf="documentsControl.controls[index].get('is_error').value == true"
                                 class="text-danger pr-1 pt-2">{{documentsControl.controls[index].get('error_message').value }}</div>
                          </label>
                        </td>
                        <td>
                          <label class="form-group has-float-label mb-0" *ngIf="is_custom_pick_list == false">
                            <div class="input-group">
                              <select class="form-control" name="expense_category"
                                      formControlName="expense_category" required
                                      (change)="getLastExpense(item, index);">
                                <!--                              <option value="0" selected disabled>select</option>-->
                                <option *ngFor="let list of category_type" value="{{list.expense_category}}">
                                  {{list.expense_category}}</option>
                              </select>
                            </div>
                            <div *ngIf="submitted && documentsControl.controls[index].get('expense_category').errors"
                                 class="text-danger">This field is required
                            </div>
                          </label>

                          <label class="form-group has-float-label" *ngIf="is_custom_pick_list == true">
                            <div class="position-relative has-icon-right">
                              <div class="input-group">
                                <ng-select [items]="category_type" class="w-100 explanation-link"
                                           [addTag]="addExpenseCategory" [addTagText]="'Hit Enter to Add Item'"
                                           aria-describedby="inputGroup-sizing-sm" [hideSelected]="true"
                                           [multiple]="false" bindLabel="expense_category" bindValue="expense_category"
                                           formControlName="expense_category" (change)="getLastExpense(item, index);">
                                </ng-select>
                              </div>
                            </div>
                            <span class="text-primary-light">customize Category <a href="javascript:;"
                                                                                   (click)="openExpenseCategoryModel(expenseCategoryModel,'new_expense',index)"><i
                              class="fa fa-pencil text-primary ml-1"></i></a></span>
                            <div *ngIf="submitted && documentsControl.controls[index].get('expense_category').errors"
                                 class="text-danger">This field is required
                            </div>
                          </label>
                        </td>
                        <td>
                          <label class="form-group has-float-label  mb-0">
                            <div class="position-relative has-icon-left">
                              <div class="input-group">
                                <input type="number" class="form-control" aria-describedby="inputGroup-sizing-sm"
                                       formControlName="expense_amount" style="max-width: 136px !important;" (blur)="validateNumberFormat(index)" required>
                                <div class="form-control-position">
                                  <span class="input-field-currency-type-size " style="padding-top: 2px">$</span>
                                </div>
                              </div>
                            </div>
                            <div *ngIf="submitted && documentsControl.controls[index].get('expense_amount').errors"
                                 class="text-danger">This field is required
                            </div>
                          </label>
                        </td>
                        <td>
                          <label class="form-group has-float-label mb-0">
                            <div class="input-group">
                              <input type="text" class="form-control" aria-describedby="inputGroup-sizing-sm"
                                     formControlName="description" name="description">
                            </div>
                          </label>
                        </td>
                        <td>
                          <div class="input-group">
                            <ng-select [items]="suppliers" bindLabel="showing_name" bindValue="business_name"
                                       class="flex-grow-1 explanation-link"
                                       [selectOnTab]="true"
                                       [addTag]="addNewBank" [multiple]="false" class="w-83-prec h-25"
                                       [addTagText]="'Hit Enter to Add Item'"
                                       [hideSelected]="true" [trackByFn]="trackByFn" [minTermLength]="2"
                                       [loading]="false"
                                       [typeahead]="false" formControlName="business_name"
                                       (keyup)="searchAgent($event);"
                                       (change)="itemChange($event,index)">
                            </ng-select>
                            <div class="input-group-append">
                              <button type="button"
                                      *ngIf="documentsControl.controls[index].get('business_name').value == null"
                                      class="btn btn-primary default pr-2 pl-2" (click)="openSupplierListModel(index)"><i
                                class="fa fa-binoculars"></i></button>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="row">
                            <div class="form-group mb-0 ">
                              <a class="badge badge-success mr-1" href='javascript:void(0)'
                                 (click)="addNewExpenseRow(index)">
                                <i class="fa fa-plus" style="font-size: 15px;"></i>
                              </a>
                              <a class="badge badge-danger mr-1" href='javascript:void(0)'
                                 (click)="removeExpense(documentsControl.controls[index].value,index)">
                                <i class="fa fa-minus" style="font-size: 15px;"></i>
                              </a>
                              <a *ngIf="come_from != 'eofy'" class="badge badge-danger mr-1" href='javascript:void(0)'
                                 (click)="copyRow(index)">
                                <i class="fa fa-copy" style="font-size: 15px;"></i>
                              </a>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr *ngIf="!isFilter">
                        <td style="min-width: 113px;">
                          <label class="form-group has-float-label  mb-0">
                            <div class="input-group">

                              <input class="form-control" placeholder="dd/mm/YYYY"
                                     name="expense_date" formControlName="expense_date" ngbDatepicker
                                     #date_s="ngbDatepicker" (click)="date_s.toggle()"
                                     (ngModelChange)="dateChange(item,index)">
                            </div>
                            <div *ngIf="submitted && documentsControl.controls[index].get('expense_date').errors"
                                 class="text-danger">This field is required
                            </div>
                            <div *ngIf="documentsControl.controls[index].get('is_error').value == true"
                                 class="text-danger pr-1 pt-2">{{documentsControl.controls[index].get('error_message').value }}</div>
                          </label>
                        </td>
                        <td>
                          <label class="form-group has-float-label mb-0" *ngIf="is_custom_pick_list == false">
                            <div class="input-group">
                              <select class="form-control" name="expense_category"
                                      formControlName="expense_category" required
                                      (change)="getLastExpense(item, index);">
                                <option *ngFor="let list of category_type" value="{{list.expense_category}}">
                                  {{list.expense_category}}</option>
                              </select>
                            </div>
                            <div *ngIf="submitted && documentsControl.controls[index].get('expense_category').errors"
                                 class="text-danger">This field is required
                            </div>
                          </label>


                          <label class="form-group has-float-label" *ngIf="is_custom_pick_list == true">
                            <div class="position-relative has-icon-right">
                              <div class="input-group">
                                <ng-select [items]="category_type" class="w-100 explanation-link"
                                           [addTag]="addExpenseCategory" [addTagText]="'Hit Enter to Add Item'"
                                           aria-describedby="inputGroup-sizing-sm" [hideSelected]="true"
                                           [multiple]="false" bindLabel="expense_category" bindValue="expense_category"
                                           formControlName="expense_category" (change)="getLastExpense(item, index);">
                                </ng-select>
                              </div>
                            </div>
                            <span class="text-primary-light">customize Category <a href="javascript:;"
                                                                                   (click)="openExpenseCategoryModel(expenseCategoryModel,'new_expense',index)"><i
                              class="fa fa-pencil text-primary ml-1"></i></a></span>
                            <div *ngIf="submitted && documentsControl.controls[index].get('expense_category').errors"
                                 class="text-danger">This field is required
                            </div>
                          </label>
                        </td>
                        <td>
                          <label class="form-group has-float-label  mb-0">
                            <div class="position-relative has-icon-left">
                              <div class="input-group">
                                <input type="number" class="form-control" aria-describedby="inputGroup-sizing-sm"
                                       formControlName="expense_amount" style="max-width: 136px !important;" (blur)="validateNumberFormat(index)" required>
                                <div class="form-control-position">
                                  <span class="input-field-currency-type-size " style="padding-top: 2px">$</span>
                                </div>
                              </div>
                            </div>
                            <div *ngIf="submitted && documentsControl.controls[index].get('expense_amount').errors"
                                 class="text-danger">This field is required
                            </div>
                          </label>
                        </td>
                        <td>
                          <label class="form-group has-float-label mb-0">
                            <div class="input-group">
                              <input type="text" class="form-control" aria-describedby="inputGroup-sizing-sm"
                                     formControlName="description" name="description">
                            </div>
                          </label>
                        </td>
                        <td>
                          <div class="input-group">
                            <ng-select [items]="suppliers" bindLabel="showing_name" bindValue="business_name"
                                       class="flex-grow-1 explanation-link"
                                       [selectOnTab]="true" [addTag]="addNewBank" [multiple]="false"
                                       [addTagText]="'Hit Enter to Add Item'" [hideSelected]="true"
                                       [trackByFn]="trackByFn" [minTermLength]="2"
                                       [loading]="false" [typeahead]="false" formControlName="business_name"
                                       (keyup)="searchAgent($event);" (change)="itemChange($event,index)">
                            </ng-select>
                            <div class="input-group-append">
                              <button type="button"
                                      *ngIf="documentsControl.controls[index].get('business_name').value == null"
                                      class="btn btn-primary default pl-2 pr-2" (click)="openSupplierListModel(index)"
                                      style="border-radius: unset!important;">
                                <i class="fa fa-binoculars"></i></button>
                            </div>
                          </div>
                        </td>
                        <td style="min-width: 98px;">
                              <a class="badge badge-success mr-1" href='javascript:void(0)'
                                 (click)="addNewExpenseRow(index)">
                                <i class="fa fa-plus" style="font-size: 15px;"></i>
                              </a>
                              <a class="badge badge-danger mr-1" href='javascript:void(0)'
                                 (click)="removeExpense(documentsControl.controls[index].value,index)">
                                <i class="fa fa-minus" style="font-size: 15px;"></i>
                              </a>
                              <a *ngIf="come_from != 'eofy'" class="badge badge-danger" href='javascript:void(0)'
                                 (click)="copyRow(index)">
                                <i class="fa fa-copy" style="font-size: 15px;"></i>
                              </a>
                        </td>
                      </tr>
                    </ng-container>
                  </ng-container>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer" style="justify-content: space-between !important;">
          <ng-container *ngIf="come_from == 'eofy'">
            <div class="d-flex align-items-left row pl-4">
              <div class="custom-control custom-checkbox align-contact mr-3">
                <input type="checkbox" class="custom-control-input pointer" id="is_sort" name="is_sort"
                       formControlName="is_sort" (click)="sortExpense($event.target.checked)">
                <label class="custom-control-label pt-0 pointer" for="is_sort"
                       style="color: lightslategrey!important; font-size: 95%">Sort by Category</label>
              </div>
              <div class="custom-control custom-checkbox align-contact ">
                <input type="checkbox" class="custom-control-input pointer" id="is_expense_checked"
                       name="is_expense_checked" formControlName="is_expense_checked"
                       (click)="isExpenseChecked = $event.target.checked ">
                <label class="custom-control-label pt-0 cursor-pointer" for="is_expense_checked"
                       style="color: lightslategrey!important; font-size: 95%">Checked & Correct</label>
              </div>
            </div>
            <div>
              <app-state-button btnClass="btn btn-primary btn-xs pull-right" [currentState]="responseWaiting"
                                message="false"
                                (click)="addExpenseDetailSubmitForEofy()">Save
              </app-state-button>
              <button type="button" class="btn btn-outline-secondary btn-xs pull-right mr-1"
                      (click)="closeModelForEofy()">Close
              </button>
            </div>
          </ng-container>

          <ng-container *ngIf="come_from != 'eofy'">
            <button type="button" class="btn btn-outline-secondary btn-xs"
                    (click)="closeModel()">Close
            </button>
            <app-state-button btnClass="btn btn-primary btn-xs" [currentState]="responseWaiting" message="false"
                              (click)="addExpenseDetailSubmit()">Save
            </app-state-button>
          </ng-container>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- Expense Category Add-->
<div bsModal #expenseCategoryModel="bs-modal" class="modal fade" tabindex="-1"
     [config]="{ ignoreBackdropClick: true}" role="dialog" aria-labelledby="dialog-sizes-name1">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Expense Category</h4>
        <button type="button" class="close pull-right" (click)="closeExpenseCategoryModel(expenseCategoryModel)"
                aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form" #expenseCategoryForm="ngForm"
              (ngSubmit)="expensesFormSubmit(expenseCategoryForm,expenseCategoryModel)">
          <div class="col-md-12 col-sm-12 pl-0 pr-0">
            <div class="row">
              <div class="">
                <table class="table table-striped">
                  <tbody>
                  <tr>
                    <td colspan="2" class="pl-1 pr-1">
                      <div class="input-group">
                        <input type="text" class="form-control" placeholder="Add Expenses Type"
                               name="expense_category_new" value="" ngModel required>
                        <div class="input-group-append">
                          <button class="btn btn-primary default" type="submit" style="border-radius: unset!important;">
                            <i class="glyph-icon simple-icon-check f-15  text-white"></i>
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <div class="pb-3"></div>
                  </tr>
                  <tr *ngFor="let expenseCategory of tempExpenseCategories;let key = index;">
                    <td *ngIf="expenseCategoryKey != key">{{expenseCategory.expense_category}}</td>
                    <td *ngIf="expenseCategoryKey == key">
                      <label class="form-group has-float-label p-0 m-0">
                        <div class="input-group">
                          <input type="text" class="form-control" aria-describedby="inputGroup-sizing-sm"
                                 name="expense_category" [ngModel]="expenseCategory.expense_category"
                                 #expense_category_edit="ngModel">
                        </div>
                        <span>Expense Category</span>
                      </label>
                    </td>
                    <td class="text-right pr-1">
                      <a *ngIf="expenseCategoryKey != key" href="javascript:void 0;" class="badge badge-primary mr-1" (click)="expenseCategoryKey = key">
                        <i  class="glyph-icon simple-icon-note text-white f-15 "></i>
                      </a>
                      <a *ngIf="expenseCategoryKey == key" href="javascript:void 0;" class="badge badge-danger mr-1 mt-1"  (click)="expenseCategoryKey = null">
                        <i  class="glyph-icon simple-icon-close f-15"></i>
                      </a>
                      <a href="javascript:void 0;" class="badge badge-primary mt-1"  (click)="saveExpenseCategories(expenseCategory,expenseCategoryForm,expenseCategoryModel)">
                        <i *ngIf="expenseCategoryKey == key" class="glyph-icon simple-icon-check f-15"></i>
                      </a>
                      <a  href="javascript:void 0;" class="badge badge-danger" (click)="deleteExpenseCategories(expenseCategory.expense_category_id)">
                        <i *ngIf="expenseCategoryKey != key" class="glyph-icon simple-icon-trash text-white f-15"></i>
                      </a>
                    </td>
                  </tr>
                  </tbody>
                  <tfoot *ngIf="tempExpenseCategories.length == 0">
                  <tr>
                    <td colspan="3">
                      No records found..
                    </td>
                  </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-xs"
                (click)="closeExpenseCategoryModel(expenseCategoryModel)">Close
        </button>
      </div>
    </div>
  </div>
</div>
<!-- Expense Category Add-->

<div bsModal #articleModel="bs-modal" class="modal fade" tabindex="-1"
     [config]="{ backdrop: 'static' ,ignoreBackdropClick: true}" role="dialog" aria-labelledby="dialog-sizes-name1">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header pb-1">
        <h4 class="modal-title pull-left">Learning Articles</h4>
        <button type="button" class="close pull-right"
                (click)="isOpenSubModal = false;articleModel.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-md-12">
          <table class="table table-striped">
            <thead>
            <tr>
              <th>
                Tutorial Name
              </th>
              <th>
                Module
              </th>
              <th>
                # Views
              </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of articles;let key=index">
              <td><a href="javascript:void(0)" class="text-primary"
                     (click)="alertWindowOpen(item)"> {{item.content_title}}</a></td>
              <td> {{item.form_name}}</td>
              <td>  <span class="badge badge-pill badge-dark" style="min-width: 75px;"><i class="fa fa-eye pr-2"
                                                                                          title="{{ item.views_count }} views"></i>{{ item.views_count }}</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-xs custom-btn-sec"
                (click)="isOpenSubModal = false;articleModel.hide()">Close
        </button>
      </div>
    </div>
  </div>
</div>
