<div bsModal #propertyImageModel="bs-modal" class="modal fade custom-modal" tabindex="-1" [config]="{ ignoreBackdropClick: true}"
     role="dialog" aria-labelledby="dialog-sizes-name1">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Property Images</h4>
        <!--        <button type="button" class="close pull-right" (click)="closePropertyPurposeModel()"-->
        <!--                aria-label="Close">-->
        <!--          <span aria-hidden="true">&times;</span>-->
        <!--        </button>-->
      </div>
      <div class="modal-body">
        <div class="modal-body pr-0">
          <div class="col-md-12 col-sm-12 pr-0">
            <div class="row ">
              <ng-container *ngFor="let item of images; index as i" >
                <div class="mt-2 mr-3 property-detail-image" *ngIf="(item.type != 'header' && item.is_delete == false)" style="display: inline-block !important;" >
                  <div class="d-flex img-box position-relative" *ngIf="item.src != 'loading'">
                    <img src="{{item.src}}" class="list-thumbnail border-0 img-responsive pointer " style=" height: 100px !important;" alt="Smiley face"   (click)="processImageClick(item,i)" />
                    <span  class="badge badge-pill position-absolute  badge-top-right badge-info pointer ">{{item.title == 'blueprint'? 'plan' :item.title }}</span>
                    <span *ngIf="comeFrom == 'property-details-edit-view'" class="badge badge-pill position-absolute  badge-top-left image badge-danger  pointer"  (click)="item.is_delete = true"><i class="glyph-icon simple-icon-trash" ></i> </span>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light"
                (click)="closePropertyImageModel()">Close</button>
      </div>
    </div>
  </div>
</div>
