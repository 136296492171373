import {EventEmitter, Injectable, Output} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {GlobalService} from "../../../shared/global.service";
import {BehaviorSubject, Observable, of} from "rxjs";
import {catchError, map, switchMap} from "rxjs/operators";

declare var gapi: any;
@Injectable({
  providedIn: 'root'
})
export class PropertyService {
  private propertyDetailsSubject = new BehaviorSubject<any>(null);
  auth: any = {};
  constructor(
    private http: HttpClient,
    private globalService: GlobalService
  ) {
    this.initClient();
  }

  initClient() {

    // gapi.load('client', () => {
    //
    //   gapi.client.init({
    //     apiKey: 'AIzaSyA4XMFqFInqHbPbqVhGn57sfGS7bachUyI',
    //     clientId: '301673974165-c6smgbavkhgcqcrrjfsm8rmmsh126mrn.apps.googleusercontent.com',
    //     discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
    //     scope: 'https://www.googleapis.com/auth/calendar'
    //   });
    //   gapi.client.load('calendar', 'v3', () => { });
    //
    //   // var auth =  gapi.auth2.getAuthInstance();
    //   this.auth = gapi.auth2.getAuthInstance();
    //   // console.log('isSignedIn',auth.isSignedIn);
    //   // if(auth.isSignedIn){
    //   //   this.loggedUser.image=auth.signIn().wt.SJ;
    //   //   this.loggedUser.name=auth.signIn().wt.Ad;
    //   // }
    // });

  }
  getProperty(pageSize: number = 10, currentPage: number = 1, search: string = '', orderBy: string = '') {
    var params = '?pageSize=' + pageSize + '&currentPage=' + currentPage + '&search=' + search + '&orderBy=' + orderBy;
    return this.http.get(this.globalService.getAPIUrl() + 'property/get-property-list' + params, this.globalService.getHttpOptions());
  }

  sortOrderProperty(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/sort-property-order', data, this.globalService.getHttpOptions());
  }

  getSnapshotValidation(property_ref: any) {
    return this.http.get(this.globalService.getAPIUrl() + 'property/snapshot/get-property-snapshot-validation-details?property_ref=' + property_ref, this.globalService.getHttpOptions());
  }

  countProppelLearningArticleView(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'client/learning-article/get-learning-article-list', data, this.globalService.getHttpOptions());
  }

  getPropertyDetails(propertyRef: any , comeFrom,isPropertyDetails = false,addressChange = false) {
    if(comeFrom == 'propertyEdit') {
      if (this.propertyDetailsSubject.value) {
        if (isPropertyDetails) {
          if (this.propertyDetailsSubject.value.properties.propertyRef !== propertyRef) {
            this.propertyDetailsSubject.next(null);
          }
        }else if(addressChange){
            this.propertyDetailsSubject.next(null);
        }
      }

      if (this.propertyDetailsSubject.value) {
        return this.propertyDetailsSubject.asObservable();
      }
      // If not, make the API call and store the response
      return this.http.get<any>(this.globalService.getAPIUrl() + 'property/get-property-details-by-ref?property_ref=' + propertyRef, this.globalService.getHttpOptions()).pipe(
        catchError((error) => {
          // Handle errors if needed
          return [];
        }),
        switchMap((response) => {
          this.propertyDetailsSubject.next(response);
          return this.propertyDetailsSubject.asObservable();
        })
      );
      //    return this.http.get(this.globalService.getAPIUrl() + 'property/get-property-details-by-ref?property_ref=' + propertyRef, this.globalService.getHttpOptions());


    }
    else {
      return this.http.get(this.globalService.getAPIUrl() + 'property/get-property-details-for-property-registration?property_ref=' + propertyRef, this.globalService.getHttpOptions());
    }
  }
  getKnowledgeDescription(label) {
    return this.http.get(this.globalService.getAPIUrl() + 'property/get-knowledge-description?label_name=' + label, this.globalService.getHttpOptions());
  }

  savePropertySellingExpense(value) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/save-property-selling-expense', value, this.globalService.getHttpOptions());
  }

  deleteSellExpense(expense_ref, propertyRef) {
    return this.http.get(this.globalService.getAPIUrl() + 'property/delete-sell-expense?property_ref=' + propertyRef + '&expense_ref=' + expense_ref, this.globalService.getHttpOptions());
  }

  hideProperty(propertyRef: string) {
    return this.http.get(this.globalService.getAPIUrl() + 'property/hide-property?property_ref=' + propertyRef, this.globalService.getHttpOptions());
  }

  restoreProperty(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/property-restore', data, this.globalService.getHttpOptions());
  }

  checkEmail(data: { email: any }) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/check-user-email', data, this.globalService.getHttpOptions());
  }

  transferTo(propertyRef: string, email) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/property-transfer-to-another-user', { "property_ref": propertyRef, "email": email }, this.globalService.getHttpOptions());
  }

  deleteProperty(propertyRef) {
    return this.http.get(this.globalService.getAPIUrl() + 'property/delete-property?property_ref=' + propertyRef, this.globalService.getHttpOptions());
  }

  shareTo(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/property-share-to-another-user', data, this.globalService.getHttpOptions());
  }
  unshareTo(data: any) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/unshare-to', data, this.globalService.getHttpOptions());
  }

  getPropertyListForPropertyValues() {
    return this.http.get(this.globalService.getAPIUrl() + 'property/get-property-list-for-property-values', this.globalService.getHttpOptions());
  }

  savePropertyValue(value: any) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/save-property-value', value, this.globalService.getHttpOptions());
  }

  getRentalIncomeList() {
    return this.http.get(this.globalService.getAPIUrl() + 'property/rental/get-rental-income-list', this.globalService.getHttpOptions());
  }

  rentalValueSubmit(value: any) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/rental/save-rental-value', value, this.globalService.getHttpOptions());
  }

  getCashFlowList() {
    return this.http.get(this.globalService.getAPIUrl() + 'property/get-cash-flow-list', this.globalService.getHttpOptions());
  }

  updateCashFlow(value: any) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/update-cash-flow', value, this.globalService.getHttpOptions());
  }

  getActiveInsuranceList() {
    return this.http.get(this.globalService.getAPIUrl() + 'property/insurance/get-active-insurance-list', this.globalService.getHttpOptions());
  }

  shareToEdit(data: { propertyRef: string; permissionList: any }) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/share-to-edit', data, this.globalService.getHttpOptions());
  }

  getMortgageList() {
    return this.http.get(this.globalService.getAPIUrl() + 'property/mortgage/get-mortgage-list', this.globalService.getHttpOptions());
  }

  updateMortgageList(value) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/mortgage/update-mortgage-list', value, this.globalService.getHttpOptions());
  }

  //Issues
  getIssuesList(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/issue/get-property-issues-list', data, this.globalService.getHttpOptions());
  }

  getIssuesByRef(issue_ref) {
    return this.http.get(this.globalService.getAPIUrl() + 'property/issue/get-issue-by-ref?issue_ref=' + issue_ref, this.globalService.getHttpOptions());
  }

  saveIssue(data, action) {
    if (action) {
      return this.http.post(this.globalService.getAPIUrl() + 'property/issue/edit-property-issue', data, this.globalService.getHttpOptions());
    }
    else {
      return this.http.post(this.globalService.getAPIUrl() + 'property/issue/add-new-property-issue', data, this.globalService.getHttpOptions());
    }
  }

  deleteIssue(issueRef) {
    return this.http.get(this.globalService.getAPIUrl() + 'property/issue/delete-property-issue?issue_ref=' + issueRef, this.globalService.getHttpOptions());
  }

  searchIssues(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'search-issues', data, this.globalService.getHttpOptions());
  }

  deleteIssueType(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/issue/delete-issue-type' ,data, this.globalService.getHttpOptions());
  }

  updateFilterStatus(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/issue/update-issue-filter-status', data, this.globalService.getHttpOptions());
  }



  getIssueTypeList(isCustomPicklist) {
    return this.http.get(this.globalService.getAPIUrl() + 'property/issue/get-issue-type-list?is_custom_pick_list=' + isCustomPicklist + '', this.globalService.getHttpOptions());
  }

  saveIssueType(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/issue/save-issue-type' ,data, this.globalService.getHttpOptions());
  }

  refreshPropertyDetails(propertyRef: string): void {
    this.http.get<any>(this.globalService.getAPIUrl() + 'property/get-property-details-by-ref?property_ref=' + propertyRef, this.globalService.getHttpOptions()).subscribe((response) => {
      this.propertyDetailsSubject.next(response);
    });
  }

  saveAssetsDetail(data, isEdit) {
    if (isEdit) {
      return this.http.post(this.globalService.getAPIUrl() + 'property/asset/edit-property-asset', data, this.globalService.getHttpOptions());
    }
    else {
      return this.http.post(this.globalService.getAPIUrl() + 'property/asset/add-new-property-asset', data, this.globalService.getHttpOptions());
    }

  }

  getAssetList(propertyRef: string) {
    return this.http.get(this.globalService.getAPIUrl() + 'property/asset/get-property-assets-list?property_ref=' + propertyRef, this.globalService.getHttpOptions());
  }

  getAssetByRef(assetsRef: any, propertyRef) {
    return this.http.get(this.globalService.getAPIUrl() + 'property/asset/get-asset-details-by-ref?property_ref=' + propertyRef + '&asset_ref=' + assetsRef, this.globalService.getHttpOptions());
  }

  getAssetDetailsForView(assetsRef: any,) {
    return this.http.get(this.globalService.getAPIUrl() + 'property/asset/get-asset-details-for-view?asset_ref=' + assetsRef, this.globalService.getHttpOptions());
  }

  deleteAssetDetail(assetsRef: any) {
    return this.http.get(this.globalService.getAPIUrl() + 'property/asset/delete-property-asset?asset_ref=' + assetsRef, this.globalService.getHttpOptions());
  }

  getAssetsForFilter(data: any) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/asset/get-assets-by-filter', data, this.globalService.getHttpOptions());
  }
  downloadAssetsImages(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'file/images_download_as_zip', data, this.globalService.getHttpOptionsBlob());
  }

  getAssetDetailsPrintView(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/asset/get-asset-details-print-view', data, this.globalService.getHttpOptions());
  }

  searchAgent(searchKey) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/rental/search-agent' , searchKey, this.globalService.getHttpOptions());
  }

  getPropertyRentalByRef( data) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/rental/get-property-rental-list', data, this.globalService.getHttpOptions());
  }

  savePropertyRentalHeader(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/rental/save-rental-header', data, this.globalService.getHttpOptions());
  }

  saveRentalDetail(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/rental/add-new-property-rent', data, this.globalService.getHttpOptions());
  }

  getRentalDetailByRef(propertyRef: string) {
    return this.http.get(this.globalService.getAPIUrl() + 'get-rental-detail-by-ref/' + propertyRef, this.globalService.getHttpOptions());
  }

  deleteRentalDetail(rentalDetailRef) {
    return this.http.get(this.globalService.getAPIUrl() + 'property/rental/delete-property-rental-detail?rental_detail_ref=' + rentalDetailRef, this.globalService.getHttpOptions());
  }

  saveAgentDetail(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/rental/save-agent-details', data, this.globalService.getHttpOptions());
  }

  saveRentalExpenseDetail(data: any) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/rental/save-rental-expenses', data, this.globalService.getHttpOptions());
  }

  updateRentalDetail(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/rental/edit-property-rent', data, this.globalService.getHttpOptions());
  }

  savePropertyIsRent(data, property_ref) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/rental/save-property-is-rent?property_ref=' + property_ref, data, this.globalService.getHttpOptions());
  }

  saveExcelRentalDetail(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/rental/save-excel-rental-detail', data, this.globalService.getHttpOptions());
  }

  saveExcelRentalSettings(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'save-rental-setting', data, this.globalService.getHttpOptions());
  }

  saveNewExpenseCategory(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/expense/save-expense-category', data, this.globalService.getHttpOptions());
  }

  deleteExpenseCategory(category_id) {
    return this.http.get(this.globalService.getAPIUrl() + 'property/expense/delete-expense-categories?expense_category_id=' + category_id, this.globalService.getHttpOptions());
  }

  showMessage(data){
    return this.http.post(this.globalService.getAPIUrl() + 'property/update-message', data, this.globalService.getHttpOptions());
  }

  LeaseMySelf(data, propertyRef: string) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/rental/property-lease-my-self?property_ref=' + propertyRef, data, this.globalService.getHttpOptions());
  }

  NoLease(data, propertyRef: string) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/rental/property-no-lease?property_ref=' + propertyRef, data, this.globalService.getHttpOptions());
  }

  getLastExpenseDetail(details) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/expense/get-last-expense-detail', details, this.globalService.getHttpOptions());
  }

  getCategoryDepreciationList(propertyRef: string) {
    return this.http.get(this.globalService.getAPIUrl() + 'property/depreciation/get-category-depreciation-list?property_ref=' + propertyRef, this.globalService.getHttpOptions());
  }
  getFormData(depreciationCategoryRef) {
    return this.http.get(this.globalService.getAPIUrl() + 'property/depreciation/get-category-depreciation-form-details?category_ref='+depreciationCategoryRef, this.globalService.getHttpOptions());
  }
  saveCategorySubmit(data, isEdit) {
    if (isEdit)
      return this.http.post(this.globalService.getAPIUrl() + 'property/depreciation/edit-property-depreciation', data, this.globalService.getHttpOptions());
    else
      return this.http.post(this.globalService.getAPIUrl() + 'property/depreciation/add-new-property-depreciation', data, this.globalService.getHttpOptions());
  }

  deleteDepreciationCategory(propertyRef, categoryRef) {
    return this.http.get(this.globalService.getAPIUrl() + 'property/depreciation/delete-depreciation-category?property_ref=' + propertyRef + '&category_ref=' + categoryRef, this.globalService.getHttpOptions());
  }

  deleteImage(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/photos/delete-image', data, this.globalService.getHttpOptions());
  }

  saveDepreciationSchedule(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/depreciation/save-property-depreciation-schedules', data, this.globalService.getHttpOptions());
  }
  saveNewSupplierDetail(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/save-new-supplier-details', data, this.globalService.getHttpOptions());
  }

  getAssets(searchKey: any, type) {
    return this.http.get(this.globalService.getAPIUrl() + 'get-search-assets?searchKey=' + searchKey + '&type=' + type, this.globalService.getHttpOptions());
  }

  deleteDepreciationSchedule(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/depreciation/delete-depreciation-schedule',data,this.globalService.getHttpOptions());
  }

  saveSupplierType(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'client/supplier/save-supplier-type' ,data, this.globalService.getHttpOptions());
  }

  checkSupplierType(supplier_type_id, propertyRef) {
    return this.http.get(this.globalService.getAPIUrl() + 'client/supplier/check-supplier-type?supplier_type_id=' + supplier_type_id + '&property_ref=' + propertyRef, this.globalService.getHttpOptions());
  }

  deleteSupplierType(supplier_type_id: any, propertyRef: string) {
    return this.http.get(this.globalService.getAPIUrl() + 'client/supplier/delete-supplier-type?supplier_type_id=' + supplier_type_id + '&property_ref=' + propertyRef, this.globalService.getHttpOptions());
  }

  hideDepreciationEducationMessage(event,property_ref) {
    return this.http.get(this.globalService.getAPIUrl() + 'property/depreciation/hide-depreciation-education-message?property_ref='+property_ref+'&event=' + event, this.globalService.getHttpOptions());
  }

  getPropertyByRef(propertyRef: string) {
    return this.http.get(this.globalService.getAPIUrl() + 'property/get-property-by-ref?property_ref=' + propertyRef, this.globalService.getHttpOptions());
  }

  //Expense Details
  getExpenseDetailByRef(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/expense/get-property-expense-list', data, this.globalService.getHttpOptions());
  }

  expenseDetailsByRef(expense_ref) {
    return this.http.get(this.globalService.getAPIUrl() + 'property/expense/expense-details-by-ref?expense_ref=' + expense_ref, this.globalService.getHttpOptions());
  }

  addNewExpense(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/expense/add-new-property-expense', data, this.globalService.getHttpOptions());
  }
  deleteExpenseDetail(expenseDetailRef) {
    return this.http.get(this.globalService.getAPIUrl() + 'property/expense/delete-property-expense?expense_ref=' + expenseDetailRef, this.globalService.getHttpOptions());
  }

  getExpenseForFilter(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/expense/get-expense-by-filter', data, this.globalService.getHttpOptions());
  }

  updateExpense(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/expense/edit-property-expense', data, this.globalService.getHttpOptions());
  }
  deletePdfFile(mediaRef: any) {

    return this.http.get(this.globalService.getAPIUrl() + 'property/expense/delete-add-new-document?media_ref=' + mediaRef, this.globalService.getHttpOptions());
  }

  getPropertySuppliers(searchKey, propertyRef) {
    return this.http.get(this.globalService.getAPIUrl() + 'property/supplier/get-property-supplier-with-filter?search_key=' + searchKey + '&property_ref=' + propertyRef, this.globalService.getHttpOptions());
  }

  saveDuplicateExpenseDetail(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/expense/save-duplicate-expense-detail', data, this.globalService.getHttpOptions());
  }

  saveImportExpenseDetail(data: { property_ref: string; data_array: any }) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/expense/save-import-expense-detail', data, this.globalService.getHttpOptions());
  }

  deleteExpenseCategoryCheck(category_id,propertyRef) {
    return this.http.get(this.globalService.getAPIUrl() + 'property/expense/delete-expense-categories-validate?expense_category_id=' + category_id+ '&property_ref=' + propertyRef, this.globalService.getHttpOptions());

  }

  //Property Other Info
  getOtherInfoPageLoadData(propertyRef: any) {
    return this.http.get(this.globalService.getAPIUrl() + 'property/other-info/get-property-other-info-list?property_ref=' + propertyRef, this.globalService.getHttpOptions());
  }

  filterOtherInfo(propertyRef, searchKey) {
    return this.http.get(this.globalService.getAPIUrl() + 'property/other-info/filter-other-info?property_ref=' + propertyRef + '&search_key=' + searchKey, this.globalService.getHttpOptions());
  }

  deleteOtherInfo(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/other-info/delete-property-other-info', data, this.globalService.getHttpOptions());
  }

  saveOtherInfo(data: any, isEdit: boolean) {
    if (isEdit) {
      return this.http.post(this.globalService.getAPIUrl() + 'property/other-info/edit-property-other-info', data, this.globalService.getHttpOptions());
    } else {
      return this.http.post(this.globalService.getAPIUrl() + 'property/other-info/add-new-property-other-info', data, this.globalService.getHttpOptions());
    }
  }

  //Property To Do List
  getToDoByRef(propertyRef) {
    return this.http.get(this.globalService.getAPIUrl() + 'property/to-do-list/get-property-to-do-list?property_ref=' + propertyRef, this.globalService.getHttpOptions());
  }

  saveToDoDetails(data: any, isEdit: boolean) {
    if (isEdit) {
      return this.http.post(this.globalService.getAPIUrl() + 'property/to-do-list/edit-property-to-do-task', data, this.globalService.getHttpOptions());
    }
    else {
      return this.http.post(this.globalService.getAPIUrl() + 'property/to-do-list/add-new-property-to-do-task', data, this.globalService.getHttpOptions());
    }
  }

  deleteToDoItem(toDoRef: any) {
    return this.http.get(this.globalService.getAPIUrl() + 'property/to-do-list/delete-property-to-do-task?to_do_ref=' + toDoRef, this.globalService.getHttpOptions());
  }

  eventSaveToDoItem(data:any){
    return this.http.post(this.globalService.getAPIUrl() + 'property/to-do-list/save-property-to-do-event-save-to-do', data, this.globalService.getHttpOptions());
  }

  saveTaskDone(data: any) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/to-do-list/save-property-to-do-task-completed', data, this.globalService.getHttpOptions());
  }

  updateTodoData(data: any) {
    return this.http.post(this.globalService.getAPIUrl() + 'update-to-do-item', data, this.globalService.getHttpOptions());
  }

  getDocuments(data :any) {
    return this.http.post(this.globalService.getAPIUrl() + 'client/document/get-document-list-with-filters',data , this.globalService.getHttpOptions());
  }




  getMortgageFormData(propertyRef) {
    return this.http.get(this.globalService.getAPIUrl() + 'property/mortgage/get-mortgage-form-data?property_ref=' + propertyRef, this.globalService.getHttpOptions());
  }

  getMortgagesForFilter(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/mortgage/get-mortgage-by-filter', data, this.globalService.getHttpOptions());
  }

  deleteMortgageDetail(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/mortgage/delete-mortgage-detail', data, this.globalService.getHttpOptions());
  }

  savePropertyIsMortgage(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/mortgage/save-property-is-mortgage', data, this.globalService.getHttpOptions());
  }

  archiveMortgage(mortgageRef: any, propertyRef: string) {
    return this.http.get(this.globalService.getAPIUrl() + 'property/mortgage/archive-mortgage?mortgage_ref=' + mortgageRef + '&property_ref=' + propertyRef, this.globalService.getHttpOptions());
  }

  restoreMortgage(mortgageRef, propertyRef) {
    return this.http.get(this.globalService.getAPIUrl() + 'property/mortgage/restore-mortgage-by-ref?mortgage_ref=' + mortgageRef + '&property_ref=' + propertyRef, this.globalService.getHttpOptions());
  }

  getMortgageHeaderFormData(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/mortgage/get-mortgage-header-form-data', data, this.globalService.getHttpOptions());
  }

  savePropertyMortgageHeaderDetails(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/mortgage/save-property-mortgage-header-details', data,this.globalService.getHttpOptions());
  }

  getMortgageDataByMortgageRef(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/mortgage/get-mortgage-data-by-mortgage-ref', data, this.globalService.getHttpOptions());
  }

  changeMortgageSupplier(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/mortgage/change-mortgage-supplier', data, this.globalService.getHttpOptions());
  }

  saveMortgageForPropertyRegistration(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/mortgage/save-mortgage-for-property-registration',data, this.globalService.getHttpOptions());
  }

  getArchiveMortgageData(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/mortgage/get-archive-mortgage-data', data, this.globalService.getHttpOptions());
  }

  deleteArchiveMortgageDetails(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/mortgage/delete-archive-mortgage-details', data, this.globalService.getHttpOptions());
  }

  getDocumentsPagination(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/document/get-property-document', data, this.globalService.getHttpOptions());
  }
  deleteDocument(documentRef) {
    return this.http.get(this.globalService.getAPIUrl() + 'property/document/delete-document?document_ref=' + documentRef, this.globalService.getHttpOptions());
  }

  saveDocuments(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/document/add-new-property-document', data, this.globalService.getHttpOptions());
  }

  saveDocuments4(data) {
    let httpOption = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + localStorage.getItem("authToken"),
      }),
    };
    return this.http.post(this.globalService.getAPIUrl() + 'property/document/upload-property-documents', data, httpOption);
  }
  saveDocuments2(data: any) {

    const apiUrl = this.globalService.getAPIUrl();
    const endpoint = 'property/document/upload-property-documents';
    return this.http.post(`${apiUrl}${endpoint}`, data);
  }

  updateDocuments(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/document/edit-property-document', data, this.globalService.getHttpOptions());
  }

  sortOrder(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'sort-photos', data, this.globalService.getHttpOptions());
  }

  getImagesByRef(propertyRef: string) {
    return this.http.get(this.globalService.getAPIUrl() + 'property/photos/get-property-photos-by-ref?property_ref=' + propertyRef, this.globalService.getHttpOptions());
  }

  uploadPhotos(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/photos/save-photos', data, this.globalService.getHttpOptions());
  }

  changeToOtherType(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'file/change-to-other', data, this.globalService.getHttpOptions());
  }

  changeToHeaderType(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'file/change-to-header', data, this.globalService.getHttpOptions());
  }

  getSelectedPropertyMortgageDetailsForFinancialYear(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'eofy-report/get-selected-property-mortgage-details-for-financial-year', data, this.globalService.getHttpOptions());
  }
  getMortgageFormInitData(propertyRef) {
    return this.http.get(this.globalService.getAPIUrl() + 'property/mortgage/get-mortgage-form-init-data?property_ref=' + propertyRef, this.globalService.getHttpOptions());
  }

  getSuppliersByPropertyRef(propertyRef: string) {
    return this.http.get(this.globalService.getAPIUrl() + 'property/supplier/get-property-supplier-list?property_ref=' + propertyRef, this.globalService.getHttpOptions());
  }

  deleteSupplier(supplierRef,propertyRef) {
    return this.http.get(this.globalService.getAPIUrl() + 'property/supplier/delete-property-supplier?supplier_ref=' + supplierRef+'&property_ref='+propertyRef, this.globalService.getHttpOptions());
  }

  saveSupplierDetail(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/supplier/add-new-property-supplier', data, this.globalService.getHttpOptions());
  }

  editSupplierDetail(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/supplier/edit-property-supplier', data, this.globalService.getHttpOptions());
  }

  getSuppliersFilterByRef(propertyRef, searchKey) {
    return this.http.get(this.globalService.getAPIUrl() + 'property/supplier/get-property-supplier-with-filter?property_ref=' + propertyRef + '&search_key=' + searchKey, this.globalService.getHttpOptions());
  }

  saveRating(data: any) {
    return this.http.post(this.globalService.getAPIUrl() + 'client/supplier/save-rating-list-view' ,data, this.globalService.getHttpOptions());
  }

  getSupplierByRef(supplierRef, propertyRef: string) {
    return this.http.get(this.globalService.getAPIUrl() + 'property/supplier/get-property-supplier-by-ref?supplier_ref=' + supplierRef + '&property_ref=' + propertyRef, this.globalService.getHttpOptions());
  }

  changeToPlanType(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'file/change-to-plan', data, this.globalService.getHttpOptions());
  }

  orderUpdate(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/photos/update-order', data, this.globalService.getHttpOptions());
  }

  //Property Insurance

  getInsuranceList(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/insurance/get-property-insurance-list', data, this.globalService.getHttpOptions());
  }

  getInsuranceFormData() {
    return this.http.get(this.globalService.getAPIUrl() + 'property/insurance/get-Insurance-Form-Data', this.globalService.getHttpOptions());
  }

  saveInsurance(data, type) {
    if (type) {
      return this.http.post(this.globalService.getAPIUrl() + 'property/insurance/edit-property-insurance', data, this.globalService.getHttpOptions());
    }
    else {
      return this.http.post(this.globalService.getAPIUrl() + 'property/insurance/add-new-property-insurance', data, this.globalService.getHttpOptions());
    }
  }

  searchInsurance(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'search-insurance', data, this.globalService.getHttpOptions());
  }

  deleteInsurance(insuranceRef) {
    return this.http.get(this.globalService.getAPIUrl() + 'property/insurance/delete-property-insurance?insurance_ref=' + insuranceRef, this.globalService.getHttpOptions());
  }

  getInsuranceByRef(insuranceRef) {
    return this.http.get(this.globalService.getAPIUrl() + 'property/insurance/get-insurance-by-ref?insurance_ref=' + insuranceRef, this.globalService.getHttpOptions());
  }

  calculatePolicyExpiryDate(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/insurance/calculate-policy-expiry-date', data, this.globalService.getHttpOptions());
  }

  saveInsurerDetail(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/insurance/save-insurer-details', data, this.globalService.getHttpOptions());
  }

  saveInsuranceExpenseDetail(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/insurance/save-insurance-expense-detail', data, this.globalService.getHttpOptions());
  }

  getInsuranceTypeList(isCustomPicklist) {
    return this.http.get(this.globalService.getAPIUrl() + 'property/insurance/get-insurance-type-list?is_custom_pick_list='+isCustomPicklist+'', this.globalService.getHttpOptions());
  }

  searchInsurer(eventElementElement) {
    return this.http.get(this.globalService.getAPIUrl() + 'property/insurance/search-insurer?search=' + eventElementElement, this.globalService.getHttpOptions());
  }

  saveInsuranceType(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/insurance/save-insurance-type' ,data, this.globalService.getHttpOptions());
  }

  deleteInsuranceType(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/insurance/delete-insurance-type' ,data, this.globalService.getHttpOptions());
  }

  //property snapshot

  getPropertyDetailsForSnapshot(propertyRef: string) {
    return this.http.get(this.globalService.getAPIUrl() + 'property/snapshot/get-property-details-for-snapshot?property_ref=' + propertyRef, this.globalService.getHttpOptions());
  }

  summaryNumbersChange(propertyRef, summaryNumber) {
    return this.http.get(this.globalService.getAPIUrl() + 'property/snapshot/get-values-by-selected-type?property_ref=' + propertyRef + '&year_type=' + summaryNumber, this.globalService.getHttpOptions());
  }
  // orderUpdate(data) {
  //     return this.http.post(this.globalService.getAPIUrl() + 'property/photos/update-order', data, this.globalService.getHttpOptions());
  // }
  getMortgageHeaderDetailsForEdit(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/mortgage/get-mortgage-header-for-edit', data, this.globalService.getHttpOptions());
  }

  checkPropertyDuplicate($address) {
    let data = {
      property_address: $address
    }
    return this.http.post(this.globalService.getAPIUrl() + 'property/get-property-duplicate',data, this.globalService.getHttpOptions());
  }

  getPropertyScraping(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/get-property-address-scraping', data, this.globalService.getHttpOptions());
  }

  saveSearchPropertyData(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/save-search-property-data',data, this.globalService.getHttpOptions());
  }

  getSuburbAutoComplete(term) {
    if (term === '') {
      return of([]);
    }
    return this.http.get(this.globalService.getAPIUrl() + 'property/search-suburbs?term=' + term,
      this.globalService.getHttpOptions()).pipe(
      map(response => response['suburbs'])
    );
  }

  savePropertyPurpose(purpose_id, purpose, propertyRef: string) {
    return this.http.get(this.globalService.getAPIUrl() + 'property/save-property-purpose?property_purpose_id=' + purpose_id + '&purpose=' + purpose + '&property_ref=' + propertyRef, this.globalService.getHttpOptions());
  }

  checkPropertyPurpose(purpose_id: any, propertyRef: string) {
    return this.http.get(this.globalService.getAPIUrl() + 'property/delete-property-purpose-validate?property_purpose_id=' + purpose_id + '&property_ref=' + propertyRef, this.globalService.getHttpOptions());
  }

  deletePropertyPurpose(purpose_id: any, propertyRef: string) {
    return this.http.get(this.globalService.getAPIUrl() + 'property/delete-property-purpose?property_purpose_id=' + purpose_id + '&property_ref=' + propertyRef, this.globalService.getHttpOptions());
  }

  getQuickUpdateDataByPropertyRef(property_ref: any) {
    return this.http.get(this.globalService.getAPIUrl() + 'property/get-quick-update-data-by-property-ref?property_ref=' + property_ref, this.globalService.getHttpOptions());
  }

  saveQuickUpdateData(data: any) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/save-quick-update-data' ,data, this.globalService.getHttpOptions());
  }

  getSuburbAutoCompleteForProperty(term) {
    return this.http.get(this.globalService.getAPIUrl() + 'property/search-suburbs?term=' + term, this.globalService.getHttpOptions());
  }

  updateMarketValue(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/comparison/update-market-value', data, this.globalService.getHttpOptions());
  }

  getFolders(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/document/get-folder-list', data, this.globalService.getHttpOptions());
  }

  verifyVerticalMenuItems() {
    return this.http.get(this.globalService.getAPIUrl() + 'property/verify-vertical-toolbar-items', this.globalService.getHttpOptions());
  }

  getPropertyMain() {
    return this.http.post(this.globalService.getAPIUrl() + 'property/get-property-main-list', {}, this.globalService.getHttpOptions());
  }

  getNotification() {
    return this.http.post(this.globalService.getAPIUrl() + 'notification/get-notification', {}, this.globalService.getHttpOptions());
  }

  readNotification(ref){
    return this.http.get(this.globalService.getAPIUrl() + 'notification/read-notification?ref=' + ref, this.globalService.getHttpOptions());
  }

  readAllNotification(){
    return this.http.get(this.globalService.getAPIUrl() + 'notification/read-all-notifications' , this.globalService.getHttpOptions());
  }
  changeImageTagType(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'file/change-to-type', data, this.globalService.getHttpOptions());
  }

  saveExpenseChanges(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'property/save-expense-changes', data, this.globalService.getHttpOptions());

  }
}
