import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {GlobalService} from "../../global.service";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {NotificationService} from "../../notification.service";
import {error} from "protractor";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-tip-of-the-day',
  templateUrl: './tip-of-the-day.component.html',
  styleUrls: ['./tip-of-the-day.component.scss']
})
export class TipOfTheDayComponent implements OnInit,OnDestroy  {
  rentalModalRef?: BsModalRef;
  @ViewChild('tipOfTheDayModal') tipOfTheDayModal?: TemplateRef<any>;
  properties: any = [];
  private tipSubscription: Subscription; // Track the subscription
  constructor(
    private globalService: GlobalService,
    private modalService: BsModalService,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.tipSubscription = this.globalService.tipOfTheDayEvent.subscribe(data => {
      if(data != null){
        this.properties = data;
        this.openModal();
      }
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe to prevent multiple instances on logout/login
    if (this.tipSubscription) {
      this.tipSubscription.unsubscribe();
    }
  }

  openModal(): void {
    this.rentalModalRef = this.modalService.show(this.tipOfTheDayModal, {
      backdrop: 'static',
      ignoreBackdropClick: true,
      class: 'modal-lg'
    });
  }

  dontShow() {
    const type = 'rental_rates';
    this.notificationService.dontShowTipOfTheDayRental(type).subscribe(response => {
      this.globalService.onSuccess(response['message']);
    }, error => {
    this.globalService.onError('Error while saving data');
    });
  }
}
