import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {PropertyGlobalService} from "../../../property-global.service";
import {ModalDirective} from "ngx-bootstrap/modal";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NgxSpinnerService} from "ngx-spinner";
import {HelperService} from "../../../helper.service";
import {PropertyService} from "../../../../views/app/properties/property.service";
import {GlobalService} from "../../../global.service";
import Swal from "sweetalert2";
import {NotificationsService, NotificationType} from "angular2-notifications";
import {TranslateService} from "@ngx-translate/core";
import {SupplierGlobalService} from "../../../supplier-global.service";

@Component({
  selector: 'app-property-sales-expense-list',
  templateUrl: './property-sales-expense-list.component.html',
  styleUrls: ['./property-sales-expense-list.component.scss']
})
export class PropertySalesExpenseListComponent implements OnInit {
  comeFrom = "";
  propertyRef = null;
  propertyExpense: any = [];
  allExpenseList: any = [];
  propertyAddress = null;
  sales_cost = null;
  hiddenModal = false
  isEdit = false;
  expenseForm: FormGroup;
  submitted = false;
  expenseCategories: any = [];
  supplierList: Supplier[] = [];
  responseWaiting = '';
  buttonDisabled = false;
  isSupplierDuplicated: boolean;
  supplierListBackup: Supplier[] = [];
  suppliersTemp: any = [];
  //new ui
  expenses: Expense[] = [];
  sellingExpenses: Expense[] = [];
  @ViewChild('propertyExpenseModel')propertyExpenseModel:ModalDirective;
  @ViewChild('addPropertyExpenseModel')addPropertyExpenseModel:ModalDirective;
  purchasePrice:number = 0;

  constructor(
    private propertyGlobalService: PropertyGlobalService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private helperService: HelperService,
    private propertyService: PropertyService,
    private globalService: GlobalService,
    private notifications: NotificationsService,
    private translate: TranslateService,
    private supplierGlobalService: SupplierGlobalService,
    private cdr: ChangeDetectorRef
  ) {
    this.initForm();
    this.propertyGlobalService.openPropertySalesExpenseModelEvent.subscribe((data) => {
      this.comeFrom = data.come_from;
      this.propertyRef = data.propertyRef;
      this.propertyExpense = data.propertyExpense;
      this.propertyAddress = data.propertyAddress;
      this.sales_cost = data.salesCost;
      this.expenseCategories = data.expenseCategories;
      this.supplierList = data.supplierList;
      this.supplierListBackup = data.supplierList;
      this.allExpenseList = data.allExpenseList
      this.sellingExpenses = [];
      this.expenses = [];
      this.expenses = this.allExpenseList;
      this.sellingExpenses.push({expense_category:'Purchase Price',
        expense_amount: data.purchasePrice,
        expense_note:'This is Main Purchase Price',
        expense_ref: null,
        full_name: null,
        property_sales_expense:1});
      this.sellingExpenses.push(...this.propertyExpense);
      this.propertyExpenseModel.show();
    });

    this.globalService.closeMainModalEvent.subscribe(()=>{
      this.hiddenModal = false;
    });

    this.supplierGlobalService.selectSupplierEvent.subscribe(data => {
      console.log(data);
      if(data['comeFrom'] == 'add-sell-cost'){
        this.setSelectedForAddExpenseModel(data);
      }
    });

    this.supplierGlobalService.customizeSupplierEvent.subscribe(data => {
      // if (data['comeFrom'] == 'rental-expense') {
      this.hiddenModal = data;
      // }
    });
  }

  initForm(){
    this.expenseForm = this.fb.group({
      payment_date: [null,[Validators.required]],
      expense_amount: [null,[Validators.required]],
      expense_category_id: [1725,[Validators.required]],
      business_name: [null,[Validators.required]],
      description: [''],
      expense_ref: [null],
      expense_date: [null],
    });
  }

  get expenseFormControls() {
    return this.expenseForm.controls;
  }
  ngOnInit(): void {
  }

  openExpenseModel() {
    this.hiddenModal = true;
    this.isEdit = false;
    this.initForm();
    this.addPropertyExpenseModel.show();
  }

  openEditExpenseData(expenseData: any) {
    this.hiddenModal = true;
    this.isEdit = false;
    this.initForm()
    expenseData['business_name'] = expenseData['full_name'];
    this.expenseForm.patchValue(expenseData);
    if(expenseData['expense_date'] != null){
      this.expenseForm.get('payment_date').setValue(this.helperService.formDateObject(expenseData['expense_date']));
    }
    if(expenseData['full_name'] != null){
      this.expenseForm.get('business_name').setValue(expenseData['full_name']);
    }
    this.addPropertyExpenseModel.show();

  }

  deleteSellExpense(expenseData: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want delete this expense!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((isConfirm) => {
      if (isConfirm.value) {
        this.spinner.show();
        this.propertyService.deleteSellExpense(expenseData['expense_ref'], this.propertyRef).subscribe(res => {
          this.spinner.hide();
          this.sales_cost = res['sell_expenses_total'];
         // this.propertyExpense = res['sell_expenses'];
          let propertyExpenseIndex = this.propertyExpense.findIndex(x => x['expense_ref'] == expenseData['expense_ref']);
          let sellingExpensesIndex = this.sellingExpenses.findIndex(x => x['expense_ref'] == expenseData['expense_ref']);
          this.propertyExpense.splice(propertyExpenseIndex, 1);
          this.sellingExpenses.splice(sellingExpensesIndex, 1);
          this.globalService.onSuccess('Delete successful');
        }, err => {
          this.spinner.hide();
          this.globalService.onError(err);
        });
      }
    }, function () { });
  }

  saveCloseSellExpenseModel() {
    this.spinner.show('propertyExpenseSpinner');
    this.propertyService.saveExpenseChanges({property_ref: this.propertyRef, property_expenses: this.sellingExpenses,all_expenses:this.expenses}).subscribe(() => {
      this.globalService.onSuccess('Expense Update Successfully');
      this.propertyExpenseModel.hide();
      const sellCost = this.calculateTotal(this.sellingExpenses);
      //remove 0 key from selling expenses
      if (this.sellingExpenses.length > 0) {
        this.sellingExpenses.splice(0, 1);
      }
      let data = {
        comeFrom: this.comeFrom,
        salesCost: sellCost,
        expenseList: this.sellingExpenses,
        AllExpenseList: this.expenses,
      };
      this.propertyGlobalService.salesExpenseModelClose(data);
      this.spinner.hide('propertyExpenseSpinner');
    }, err => {
      this.spinner.hide('propertyExpenseSpinner');
      this.globalService.onError(err);
    });

  }
  CloseSellExpenseModel() {
    Swal.fire({
      title: 'Do you want to close?',
      text: 'Unsaved data will be lost!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, close it!'
    }).then((isConfirm) => {
      if (isConfirm.value) {
        this.propertyExpenseModel.hide();
        let data = {
          comeFrom: this.comeFrom,
          salesCost: this.sales_cost,
          expenseList: this.propertyExpense,
          AllExpenseList: this.allExpenseList,
        };
        this.propertyGlobalService.salesExpenseModelClose(data);
      }
    });
  }

  closeAddSellExpenseModel() {
    this.hiddenModal = false;
    this.addPropertyExpenseModel.hide();
  }

  saveSellExpense() {
    if (!this.expenseForm.valid || this.buttonDisabled) {
      return;
    }

    this.buttonDisabled = true;
    this.responseWaiting = 'show-spinner';
    this.expenseForm.get('expense_date').setValue(this.helperService.extractDateString(this.expenseForm.get('payment_date').value));
    //this.expenseForm.addControl('property_ref', this.fb.control(this.propertyRef));
    this.expenseForm.value['property_ref'] = this.propertyRef;
    this.propertyService.savePropertySellingExpense(this.expenseForm.value).subscribe(response => {
      this.responseWaiting = '';
      this.buttonDisabled = false;
        this.globalService.onSuccess('Expense Save Successfully');
        this.responseWaiting = '';
        this.addPropertyExpenseModel.hide();
        this.hiddenModal = false;
        this.sales_cost = response['sell_expenses_total'];
       // this.propertyExpense = response['expenses'];
        if(this.expenseForm.value['expense_ref'] == null){
          this.propertyExpense.push(response['expense']);
          this.sellingExpenses.push(response['expense']);
          this.mapSupplier(response['expense']['supplier']);
        }else{
          let propertyExpenseIndex = this.propertyExpense.findIndex(x => x['expense_ref'] == this.expenseForm.value['expense_ref']);
          let sellingExpensesIndex = this.sellingExpenses.findIndex(x => x['expense_ref'] == this.expenseForm.value['expense_ref']);
          this.propertyExpense[propertyExpenseIndex] = response['expense'];
          this.sellingExpenses[sellingExpensesIndex] = response['expense'];
        }
        //remove other elements from selling expenses without 0 element and
       // this.supplierList = response['suppliers'];

    }, err => {
      this.buttonDisabled = false;
      this.globalService.onError(err);
    });
  }

  addNewSupplier(addedName) {
    return { business_name: addedName, supplier_id: '', user_supplier_id: '',showing_name:addedName };
  }

  searchValue(event) {
    if (event['target']['value'] != "") {
      this.isSupplierDuplicated = false;
      this.globalService.getSuppliers(event['target']['value'],this.supplierListBackup).subscribe(response => {
          response['suppliers']?.forEach(element => {
            if (element['business_name'].toLowerCase().trim() == event['target']['value'].toLowerCase().trim()) {
              this.isSupplierDuplicated = true;
              return
            }
          });
          this.supplierList = response['suppliers'];
          this.selectedSupplierMerge();
        },
        err => {
          this.onError(err.message);
          this.spinner.hide();

        });

    }
    else {
      this.supplierList = this.supplierListBackup;
    }
  }



  selectedSupplierMerge() {
    if(this.suppliersTemp.length > 0){
      this.suppliersTemp.forEach(element => {
        let index = this.suppliersTemp.findIndex(x => x['business_name'] == element['business_name']);
        if(index == -1){
          this.supplierList.push(element);
        }
      });
    }
  }

  clearSupplier() {
    this.supplierList = this.supplierListBackup;
    this.selectedSupplierMerge();
  }

  supplierChange(event, modelName) {
    if(event != undefined){
      if(event.user_supplier_id == ""){
        let data = {
          'comeFrom':modelName,
          'supplier_type_id':'all',
          'supplier_type':'all',
          'supplier_name':event['business_name'],
          'property_ref':this.propertyRef,
        }
        this.hiddenModal = true;
        this.addPropertyExpenseModel.hide();
        this.supplierGlobalService.openNewSupplierModel(data);
      }
      else {
        let details = {
          'business_name': event['business_name'],
          'user_supplier_id': event['user_supplier_id'],
          'supplier_id': event['supplier_id'],
          'showing_name': event['showing_name'],
        }
        this.suppliersTemp.push(details);
      }
    }
  }

  openSupplierListModel(modelName) {
    let data = {
      'comeFrom':modelName,
      'supplier_type':null,
      'property_ref':this.propertyRef,
    }
    this.hiddenModal = true;
    this.addPropertyExpenseModel.hide();
    this.supplierGlobalService.openSupplierListModel(data);
  }


  private setSelectedForAddExpenseModel(data) {
    if(data['supplier'] != null){
      let agent_data = {
        'company_name': data['supplier'].company_name,
        'user_supplier_id': data['supplier'].user_supplier_id,
        'supplier_id': data['supplier'].supplier_id,
        'supplier_type':'all'
      }

      this.globalService.getSupplierList(agent_data).subscribe(res=>{
          this.supplierList = res['suppliers'];
          let showingName = data['supplier'].company_name;
          if(data['supplier'].state !=null){
            showingName = showingName + " ("+data['supplier'].state+")";
          }

          let sData = {
            'supplier_id': data['supplier'].supplier_id,
            'business_name': data['supplier'].company_name,
            'user_supplier_id': data['supplier'].user_supplier_id,
            'showing_name':showingName,
          };
          this.suppliersTemp.push(sData);
          this.supplierListBackup.push(sData);

          this.expenseForm.get('business_name').setValue(data['supplier'].company_name);
          this.hiddenModal = true;
          this.addPropertyExpenseModel.show();
        },
        error=>{
          this.onError(error.message);
        });

    }
    else {
      this.expenseForm.get('business_name').setValue(null);
    }

  }

  onError(msg) {
    this.notifications.create(this.translate.instant('alert.error'), msg, NotificationType.Error, { timeOut: 3000, showProgressBar: true });
  }

  moveToSelling(expense: Expense) {
    // Remove from expenses
    this.expenses = this.expenses.filter(e => e !== expense);
    // Add to selling expenses
    expense.property_sales_expense = 1;
    this.sellingExpenses.push(expense);
  }

  removeFromSelling(expense: Expense) {
    // Remove from selling expenses
    this.sellingExpenses = this.sellingExpenses.filter(e => e !== expense);
    // Add back to expenses
    expense.property_sales_expense = 0;
    this.expenses.push(expense);
  }

  calculateTotal(expenseList: Expense[]): number {
    const total = expenseList.reduce((total, expense) => total + expense.expense_amount, 0);
    return parseFloat(total.toFixed(2));
  }

  private mapSupplier(supplier: any) {

    const supplierExists = this.supplierList.some(existingSupplier =>
      existingSupplier.business_name === supplier.business_name
    );
    // If supplier doesn't exist, add it to the list
    if (!supplierExists) {
      this.supplierList.push(supplier);
      this.cdr.detectChanges(); // Trigger change detection manually
    } else {
      console.error("Supplier already exists:", supplier.business_name);
    }
  }
}
interface Expense {
  expense_category: string;
  expense_amount: number;
  expense_note : string|null;
  expense_ref : string|null;
  full_name : string|null;
  property_sales_expense:boolean|number;
  expense_date?: string|null;
  supplier?: any;
}

interface Supplier {
  business_name: string;
  supplier_id?: number|null;
  user_supplier_id?: number|null;
  showing_name: string|null;
}
