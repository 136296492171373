import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid'; // Or another UUID library
@Injectable({
  providedIn: 'root'
})
export class CacheService {
  async clearCacheForFile(filename: string): Promise<void> {
    if (typeof window === 'undefined' || !('caches' in window)) {
      return; // Do nothing if the environment does not support caches
    }

    // Generate random name for cache
    const cacheName = Math.random().toString(36).substring(2);

    try {
      const cache = await caches.open(cacheName);

      const keys = await cache.keys();
      for (const key of keys) {
        if (key.url.includes(filename)) {
          await cache.delete(key);
        }
      }
    } catch (error) {
    }
  }
}
