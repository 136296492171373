import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {GlobalService} from "./global.service";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private http: HttpClient,
    private globalService: GlobalService
  ) { }

  dontShowTipOfTheDayRental(type) {
    return this.http.get(this.globalService.getAPIUrl() + 'notification/hide-tip-of-the-day?type='+type, this.globalService.getHttpOptions());
  }
}
