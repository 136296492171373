import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor() { }

  extractDateString(dateObj) {

    if (dateObj != null)
      return dateObj.year + "-" + dateObj.month + "-" + dateObj.day;
    return "";
  }

  formDateObject(dateStr) {
    let date = new Date(dateStr);
    return { year: date.getFullYear(), month: (date.getMonth() + 1), day: date.getDate() };
  }

  calculateLoanEndDate(loan_term, s_date) {
   let start_date = null
    if(s_date!= null){
      let sdate = this.extractDateString(s_date);
      let str_date = new Date(sdate);
      str_date.setMonth((str_date.getMonth() + (loan_term * 12)));
      start_date = this.formDateObject(str_date);
    }

    return  start_date;
    // let start_date = new Date(s_date);
    // // start_date.setMonth((loan_term*12));
    // start_date.setMonth((start_date.getMonth() + (loan_term * 12)));
    // return  start_date;
  }

  calculateCurrentLoanBalance(isRedrawFacility,totalLoanAmount,redrawAmount) {
    // console.log(isRedrawFacility);
    let redraw = 0;
    let currentLoanBalance = null;
    if (totalLoanAmount != null){
      if (isRedrawFacility == 1) {
        if (redrawAmount != null && redrawAmount != undefined) {
          redraw = redrawAmount;
        }
      }

      currentLoanBalance = totalLoanAmount - redraw;
    }
    return currentLoanBalance;
  }

  calculateRedraw(isRedrawFacility,totalLoanAmount,currentLoanBalance){
    let redraw = null;
    if (totalLoanAmount != null){
      if (isRedrawFacility == 1) {
        redraw = totalLoanAmount - currentLoanBalance;
      }
      else {
        redraw = 0;
      }
    }

    return redraw;
  }

  dateValidateForEOFYear(date,financialYear) {
    let strD = financialYear.split('-');
    var from = new Date(strD[0], 6, 1);
    var to = new Date(strD[1], 5, 31);
    var check = new Date(date.year, (date.month)-1, date.day);
    return (check <= to && check >= from);
  }

}
