<ng-template #tipOfTheDayModal>
  <div class="modal-header bg-primary text-white">
    <h5 class="modal-title" id="dialog-sizes-name1">Tip of the Day: Rental Insights</h5>
    <button type="button" class="close text-white" aria-label="Close" (click)="rentalModalRef?.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <p class="font-weight-bold text-secondary">
      We are pleased to inform you that we have found potentially high rental values for the following properties:
    </p>

    <table class="table table-bordered table-hover text-center mt-4">
      <thead class="thead-light">
      <tr>
        <th>Property Address</th>
        <th>Rent (per week)</th>
        <th>Rental Median</th>
        <th>Link</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let property of properties">
        <td class="font-weight-bold">{{ property.property_address }}</td>
        <td class="text-danger font-weight-bold">{{ property.current_rent_pw | currency:'USD':'symbol' }}
          <span class="text-danger">&#9660;</span>
        </td>
        <td class="text-success font-weight-bold">{{ property.rental_median | currency:'USD':'symbol' }}
          <span class="text-success">&#9650;</span>
        </td>
        <td>
          <a [routerLink]="['/app/properties/rental', property.property_ref]"
             target="_blank"
             class="btn btn-outline-primary btn-sm">
            View
          </a>

        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="dontShow();rentalModalRef?.hide()">Dismiss and Don’t Show Again</button>
  </div>

</ng-template>
